import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { IdentitySettings } from 'environments/environment-type';
import { UserManager, User } from 'oidc-client';

export interface TokenResponse {
	id_token: string | undefined;
	access_token: string;
	expires_in: number;
	token_type: string;
	refresh_token: string;
	scope: string;
}

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	settings: IdentitySettings;

	loggedIn = new EventEmitter<void>();
	loggedOut = new EventEmitter<void>();

	accessToken?: string;
	refreshToken?: string;
	idToken?: string;

	refreshHandle?: ReturnType<typeof setTimeout>;

	profile?: any;
	customerId = 0;
	expiration = new Date(0);

	user!: Promise<User | null>;

	private userManager: UserManager;

	constructor() {
		this.settings = {
			authority: environment.oidc.authority,
			scope: environment.oidc.scope,
			responseType: environment.oidc.response_type
		} as IdentitySettings;
		const session = localStorage.getItem('tokenResponse');
		let accessTokenDecoded = false;
		if (session !== null) {
			accessTokenDecoded = this.decodeAccessToken(JSON.parse(session));
		}
		if (accessTokenDecoded) {
			this.loggedIn.emit();
		}

		const userManagerSettings = {
			...environment.oidc,
			response_type: 'code' // we'll always be using PKCE with userManager, the resource owner flow doesn't use userManager
		};
		this.userManager = new UserManager(userManagerSettings);
	}

	async getUser(organizationId: number): Promise<User | null> {
		this.user = this.userManager.getUser();

		let user = await this.user;
		if (user === null || user.expired) {
			// need to fix this to use content service language
			await this.loginRedirect(organizationId, 'login', '', '', '');
			this.user = this.userManager.getUser();
			user = await this.user;
		}
		if (!!user) {
			this.profile = user?.profile;
			this.accessToken = `${user.token_type} ${user.access_token}`;
			this.refreshToken = user.refresh_token;
			this.customerId = +this.profile.customerId;
			this.expiration = new Date(user.expires_at * 1000);
			const tokenResponse = {
				id_token: user.id_token,
				access_token: user.access_token,
				expires_in: user.expires_at,
				token_type: user.token_type,
				refresh_token: user.refresh_token,
				scope: user.scope
			} as TokenResponse;
			localStorage.setItem('tokenResponse', JSON.stringify(tokenResponse));
			this.loggedIn.emit();
		}

		return user;
	}

	async loginRedirect(organizationId: number, notAnAction: string, leadSource: string, language: string, partialSignUpGuid: string) {
		await this.userManager.signinRedirect({
			extraQueryParams: {
				notAnAction,
				language: language,
				organizationId: `${organizationId}`,
				leadSource: leadSource,
				partialSignUpGuid: partialSignUpGuid
			}
		});
	}

	async login(username: string, password: string): Promise<'success' | Error> {
        try {
            const response = await fetch(`${this.settings.authority}/connect/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams({
                    grant_type: 'password',
                    client_id: window.location.host,
                    username,
                    password,
                    scope: this.settings.scope,
                    response_type: this.settings.responseType
                }),
            });

            const responseObject = await response.json();
            if (responseObject.error !== undefined) {
                return new Error(responseObject.error_description);
            }
            localStorage.setItem('tokenResponse', JSON.stringify(responseObject));

            const accessTokenDecoded = this.decodeAccessToken(responseObject);

            if (accessTokenDecoded) {
                this.loggedIn.emit();
            }
        }
        catch (error: any) {
            return new Error('unknown_error');
        }

        return 'success';
	}

	async logoutRedirect() {
		await this.userManager.signoutRedirect({
			id_token_hint: this.idToken,
			extraQueryParams: {
				notAnAction: 'platform'
			}
		});
		delete this.idToken;
		delete this.accessToken;
		delete this.refreshToken;
		delete this.profile;
		this.customerId = 0;
		this.expiration = new Date(0);

		localStorage.removeItem('tokenResponse');

		this.loggedOut.emit();
	}

	logout(): void {
		delete this.accessToken;
		delete this.refreshToken;
		delete this.profile;
		this.customerId = 0;
		this.expiration = new Date(0);

		localStorage.removeItem('tokenResponse');

		this.loggedOut.emit();
	}

	refreshPopup(user: Promise<User | undefined>): Promise<void> {
		this.user = user.then(u => u ?? null);
		return this.user.then(() => { });
	}

	async refresh() {
		const response = await fetch(`${this.settings.authority}/connect/token`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body: new URLSearchParams({
				grant_type: 'refresh_token',
				client_id: window.location.host,
				refresh_token: this.refreshToken!
			}),
		});

		const responseObject = await response.json();
		if (responseObject.error !== undefined) {
			this.logout();
			return;
		}
		localStorage.setItem('tokenResponse', JSON.stringify(responseObject));

		const accessTokenDecoded = this.decodeAccessToken(responseObject);
		if (!accessTokenDecoded) {
			this.loggedOut.emit();
		}
	}

	isAuthenticated(): boolean {
		return this.expiration > new Date();
	}

	private decodeAccessToken(response: any): boolean {
		if (response.access_token === undefined) {
			return false;
		}

		if (!!response.id_token) {
			this.idToken = response.id_token;
		}

		this.accessToken = `${response.token_type} ${response.access_token}`;
		this.refreshToken = response.refresh_token;

		let base64: string = response.access_token.split('.')[1].replace('-', '+').replace('_', '/');
		this.profile = JSON.parse(atob(base64));
		this.customerId = +this.profile.customerId;
		this.expiration = new Date(this.profile.exp * 1000);

		if (this.refreshHandle !== undefined) {
			clearTimeout(this.refreshHandle);
		}

		// Subtract one minute from expiration to determine when to set a timeout for refresh.
		const expirationInterval = this.expiration.valueOf() - 60 * 1000 - new Date().valueOf();
		if (expirationInterval < 0) {
			this.refresh();
		}
		else {
			this.refreshHandle = setTimeout(() => this.refresh(), expirationInterval);
		}
		return true;
	}
}
